@import "../../global.scss";

.contact {
    background-color: #f6f8f5;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mobile {
        align-items: center;
    }

    .title {
        height: 8.77vh;
        text-align: center;
        font-weight: 300;
        font-size: 1.95vw;

        @include mobile {
            height: 10%;
            width: 80%;
            text-align: left;
            font-size: 28px;
        }
    }

    .wrapper {
        display: flex;
        margin: 2% 15% 0% 15%;
        justify-content: space-between;

        @include mobile {
            margin: 0;
            height: 80%;
            width: 80%;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-start;
        }

        .box-link {
            text-decoration: none;
            color: inherit;

            @include mobile {
                width: 100%;
                display: flex;
                height: 30%;
            }
          
            .box{
                background-color: white;
                border: 0.15vh dashed #D8D8D8;
                flex: 1;
                width: 18.68vw;
                height: 20.23vw;
                transition: background-color 0.3s ease;

                @include mobile {
                    padding: 2%;
                    display: flex;
                    align-items: center;
                    height: 80%;
                }

                .logo {
                    margin: 9.5vw 0 0 7%;
                    width: 3.33vw;
                    height: 3.33vw;
                    filter: grayscale(50%);
                    transition: filter 0.3s ease;

                    @include mobile {
                        flex: 2;
                        margin: 0;
                        width: 100%;
                        height: 100%;
                        filter: grayscale(0%);
                    }
                }

                .text-wrapper {
                    @include mobile {
                        flex: 9;
                        display: flex;
                        flex-direction: column;
                        margin-left: 5%;
                        justify-content: center;
                    }
                }
            
                .headline {
                    margin: 5% 0 0 7%;
                    font-weight: 300;
                    font-size: 1.56vw;
                    height: 2.33vw;

                    @include mobile {
                        margin: 0;
                        font-size: 25px;
                        height: auto;
                    }
                }
            
                .text {
                    margin: 0 0 0 7%;
                    font-weight: 300;
                    font-size: 1.17vw;

                    @include mobile {
                        margin: 0;
                        font-size: 18px;
                    }
                }
                
                &:hover {
                    background-color: #F0F0F0;
                  }

                &:hover .logo {
                    filter: grayscale(0%);
                }

                &:hover .headline {

                    font-weight: 400;

                    span {
                        padding-bottom: 0.2vh;
                        border-bottom: 0.2vh solid black;
                    }
                }
            }
        }
    }
}