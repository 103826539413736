.languagetoggle {
  display: flex;
  width: 15vh;
  justify-content: space-between;
  align-items: center;

  span {
    cursor: pointer;
    font-size: 2.9vh;
    font-weight: 300;
  }
  
  .selected {
    font-weight: 500;
    border-bottom: 0.18vh solid black;
    cursor: default;
  }
  
  .separator {
    font-size: 4vh;
    font-weight: 300;
  }
}