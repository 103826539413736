@import "../../global.scss";

.why {
    background-color: #F6F8F5;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
        justify-content: center;
    }

    .title {
        margin-top: 10vh;
        width: 66.54vw;
        height: 12vh;
        font-size: 3.55vw;
        font-weight: 300;
        text-align: left;

        @include mobile {
            margin-top: 8%;
            width: 80%;
            height: 10%;
            font-size: 28px;
            font-weight: 300;
            text-align: left;
        }
    }

    .grid {
        margin-top: 8.5vh;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5vh;

        @include mobile {
            height: 90%;
            grid-template-rows: repeat(4, 22%);
            gap: 2%;
            width: 80%;
            margin-top: 0;
            grid-template-columns: 1fr;
        }

        .box {
            margin-top: 1vh;
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            @include mobile {
                margin-top: 0;
                align-items: center;
            }

            .logo {
                width: 4.88vw;
                height: 4.88vw;

                @include mobile {
                    width: 100%;
                    height: 100%;
                    flex: 2.5;
                }

            }

            .wrapper {
                margin-left: 2vh;
                height: 20vh;
                width: 26.62vw;
                display: flex;
                flex-direction: column;
                justify-content: baseline;

                @include mobile {
                    justify-content: center;
                    margin-left: 5%;
                    height: 100%;
                    flex: 10;
                }

                .box-title {
                    font-size: 1.77vw;
                    font-weight: 300;
                    margin-bottom: 1vh;

                    @include mobile {
                        margin: 0;
                        font-size: 20px;
                    }
                }

                .box-text {
                    font-size: 1.33vw;
                    font-weight: 300;

                    @include mobile {
                        margin: 2% 0 0 0;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}