.sidebar {
    height: 87.72%;
    width: 300px;
    background-color: black; 
    position: fixed;
    z-index: 2;
    right: -300px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.5s ease;

    &.active {
        right: 0;
    }
    
    .wrapper {
        display: flex;
        flex-direction: column;

        a {
            margin-top: 8vh;
            text-decoration: none;
            width: 25vh;
            height: 6vh;
            text-align: left;
            color: #b4b4b4;
            font-weight: 300;
            font-size: 4vh;
    
            &:hover {
                color: white;
                font-weight: 400;
            }
        }
    }

    .languagetoggle {
        width: 22vh;
        position: absolute;
        bottom: 2.5vh;
        right: 1vw;

        span {
            font-size: 4vh;
            color: #b4b4b4;

        }
        .selected {
            color: black;
            background-color: white;
            border-bottom: none;
        }
    }
}