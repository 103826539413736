@import "../../global.scss";

.intro {

    display: flex;
    background-color: #f6f8f5;

    @include mobile {
        flex-direction: column;
        justify-content: center;
        line-height: 1.2;

        .right::before {
            content: "";
            position: relative;
            top: 3%;
            width: 45%;
            border-top: 1px solid black;
        }
    }

    .left {
        flex: 1;
        margin-top: 30vh;

        @include mobile {
            flex: none;
            margin-top: 0;
            width: 100%;
        }

        .left_1 {
            padding-left: 15%;
            font-size: 5.45vw;
            font-weight: 300;

            @include mobile {
                padding-left: 5%;
                font-size: 55px;
                text-align: left;
            }
        }

        .left_2 {
            padding-left: 20%;
            margin-top: 0.53vh;
            font-size: 3.11vw;
            font-weight: 300;

            @include mobile {
                padding-left: 10%;
                font-size: 36px;
            }
        }
    }

    .right {
        flex: 1;
        margin-top: 35vh;
        margin-bottom: 15vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-left: 0.2vh solid black;

        @include mobile {
            flex: none;
            margin-top: 10%;
            margin-bottom: 0;
            padding-left: 5%;
            border-left: none;
        }

        .right_1 {
            padding-top: 1.75vh;
            padding-right: 35%;
            padding-left: 5%;
            font-size: 1.95vw;
            font-weight: 300;

            @include mobile {
                padding-top: 3vh;
                padding-left: 0;
                padding-right: 5%;
                font-size: 25px;
            }
            
        }

        .right_2 {
            padding-top: 1.75vh;
            padding-right: 20%;
            padding-left: 5%;
            font-size: 1.4vw;
            font-weight: 300;
            line-height: 1.5;

            @include mobile {
                padding-left: 0;
                padding-right: 5%;
                font-size: 16px;
                line-height: 1.8;
            }
        }
    }

    
}