@import "../../global.scss";

.topbar {
    background-color: #f6f8f5;
    width: 100%;
    height: 12.28%;
    position: fixed;
    top: 0;
    z-index: 2;
    box-shadow: 0 0.1vh 0.2vh rgba(0, 0, 0, 0.2);

    .wrapper{
        display: flex;
        justify-content: space-between;
        padding: 1.75vh 2.5vw;
        align-items: center;

        .left{
            display: flex;
            width: 100vh;
            align-items: center;
        
            @include mobile {
                width: 80%;
            }

            .logo{
                width: 25%;
                font-family: Arial;
                font-size: 7vh;
                font-weight: 700;
                text-decoration: none;
                color: inherit;

                @include mobile {
                    width: 80%;
                }
            };

            .itemContainer{
                width: 60%;
                margin-left: 5vh;
                display: flex;

                @include mobile {
                    display: none;
                }

                a{
                    flex: 1;
                    font-size: 3.2vh;
                    text-align: left;
                    text-decoration: none;
                    color: inherit;
                }
            }
        }

        .right{
            display: flex;
            justify-content: flex-end;

            .languagetoggle {
                @include mobile {
                    display: none;
                }
            }
        }
    }
}