@import "../../global.scss";

.services {
    background-color: #f6f8f5;
    display: flex;

    @include mobile {
        flex-direction: column;
    }

    .left {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mobile {
            flex: 1;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }

        .left-wrapper {

            @include mobile {
                margin-left: 10%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 50%;
            }

            .left_1 {
                margin-left: 15%;
                font-size: 2.33vw;
                font-weight: 300;

                @include mobile {
                    margin: 0;
                    font-size: 25px;
                }
    
            }
    
            .left_2 {
                margin-left: 15%;
                margin-top: 0.88vh;
                font-size: 2.72vw;
                font-weight: 300;

                @include mobile {
                    margin: 1% 0 0 0;
                    font-size: 22px;
                }
            }
        }

        .horizontal_line {
            margin-left: 15%;
            margin-right: 50%;
            margin-top: 20%;
            border-bottom: 0.26vh solid black;

            @include mobile {
                display: none;
            }

        }

        .left_3 {
            margin: 10% 20% 0 15%;
            font-size: 1.24vw;
            font-weight: 300;

            @include mobile {
                display: none;
            }
        }
    }

    .right {
        flex: 5;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
            flex: 6;
        }

        .wrapper {
            margin: 0 10% 0 5%;
            height: 29.5vw;
            display: flex;
            justify-content: space-between;

            @include mobile {
                margin: 0 0 5% 0;
                height: 95%;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }
    

            > * {
                padding-top: 5%;
                width: 30%;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: white;
                overflow: hidden;

                @include mobile {
                    flex-direction: row;
                    justify-content: flex-start;
                    padding: 0 5% 0 5%;
                    width: 75%;
                    height: 30%;
                }

                .right-wrapper {
                    @include mobile {
                        flex: 10;
                        margin-left: 5%;
                        display: flex;
                        flex-direction: column;
                    }
                }

                .logo {
                    width: 4.67vw;
                    height: 4.67vw;

                    @include mobile {
                        flex: 2;
                        width: 100%;
                        height: 100%;
                    }
                }

                .title {
                    margin-top: 10%;
                    font-size: 1.56vw;
                    font-weight: 400;
                    text-align: center;

                    @include mobile {
                        margin: 0;
                        text-align: left;
                        font-size: 18px;
                        font-weight: 300;
                    }
                }

                .subtitle {
                    font-size: 0.94vw;
                    font-weight: 300;
                    text-align: center;

                    @include mobile {
                        margin: 0;
                        text-align: left;
                        font-size: 12px;
                    }
                }

                .text {
                    margin: 5% 5% 0 5%;
                    font-size: 1.24vw;
                    font-weight: 300;

                    @include mobile {
                        margin: 2% 0 0 0;
                        text-align: left;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}