@import "../../global.scss";

.burger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    outline: none;
    height: 5.27vh;
    width: 5.27vh;
    border: 0;
    padding: 0;
    background-color: transparent;
    transition: all 250ms ease-out;
    cursor: pointer;
    display: none;

    @include mobile {
      display: flex;
    }
  }
  
  .burger:before, .burger:after {
    content: '';
    width: 5.27vh;
    height: 0.44vh;
    position: absolute;
    background: black;
    transition: all 250ms ease-out;
    will-change: transform;
  }
  
  .burger:before {
    transform: translateY(-1.23vh);
  }
  
  .burger:after {
    transform: translateY(+1.23vh);
  }
  
  .active.burger:before {
    transform: translateY(0) rotate(45deg);
  }
  
  .active.burger:after {
    transform: translateY(0) rotate(-45deg);
  }