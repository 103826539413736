.App {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    .sections{
        width: 100%;
        top: 12.28%;
        height: 87.72%;
        position: relative;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        };

        > * {
            width: 100vw;
            height: 100%;
            scroll-snap-align: start;
        }
    }
}